@font-face {
  font-display: swap;
  font-family: "WUS";
  font-weight: 400;
  font-style: normal;
  src: url("WonderUnitSans-Regular.woff2") format("woff2-variations");
}

@font-face {
  font-display: swap;
  font-family: 'WUS';
  font-weight: 400;
  font-style: italic;
  src: url('WonderUnitSans-RegularItalic.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: "WUS";
  font-weight: 600;
  font-style: normal;
  src: url("WonderUnitSans-Bold.woff2") format("woff2-variations");
}

@font-face {
  font-display: swap;
  font-family: "WUS";
  font-weight: 900;
  font-style: normal;
  src: url("WonderUnitSans-Black.woff2") format("woff2-variations");
}

@font-face {
  font-display: swap;
  font-family: "WUS";
  font-weight: 900;
  font-style: italic;
  src: url("WonderUnitSans-BlackItalic.woff2") format("woff2-variations");
}

@font-face {
  font-display: swap;
  font-family: "BAS";
  font-weight: 800;
  font-style: normal;
  src: url("BasementGrotesque-Black_v1.202.woff2") format("woff2-variations");
}